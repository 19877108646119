import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query($slug: String!) {
    contentfulProjects(slug: { eq: $slug }) {
      seo {
        title
        description
        keywords
      }
      id
      name
      slug
      description {
        json
      }
      mainImage {
        fluid {
          src
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images {
        fluid {
          src
          ...GatsbyContentfulFluid_withWebp
        }
        title
        id
      }
    }
  }
`

const SingleGallery = ({ data }) => {
  const project = data.contentfulProjects
  const images = project.images.map(image => image.fluid.src)

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout>
      <SEO
        title={project.seo?.title || ""}
        description={project.seo?.description || ""}
        keywords={project.seo?.keywords || ""}
      />
      <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title={project.name}>
                <span>{project.name}</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link> / <Link to="/projects/">Projects</Link>{" "}
                / <span>{project.name}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding ptb-xs-40">
        <div className="container">
          <div className="row pb-10">
            <div className="col-md-12">
              <ul className="container-filter categories-filter">
                <li>
                  <Link to="/projects/">Back to Projects</Link>
                </li>
              </ul>
            </div>
          </div>

          <div id="project-detail-section" class="padding pb-60">
            <div class="container">
              <div class="row ">
                <div class="col-sm-12">
                  <div class="heading-box pb-30 ">
                    <h2>
                      <span>{project.name}</span>
                    </h2>
                    <span class="b-line l-left"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="project-details">
                    <figure>
                      {project.mainImage && (
                        <Img
                          fluid={project.mainImage.fluid}
                          alt={project.name}
                        />
                      )}
                    </figure>
                    {/* <div class="project-info col-sm-12 col-lg-4 ">
                      <h3>Project Description</h3>
                      <ul>
                        <li>
                          <strong>Client:</strong> Interior Company
                        </li>
                        <li>
                          <strong>Location:</strong> London Donec eleifend 96502
                        </li>
                        <li>
                          <strong>Year Completed:</strong> 2017
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div class="col-sm-12 col-lg-7 mt-30">
                  <div class="text-content">
                    {documentToReactComponents(project?.description?.json)}
                  </div>
                </div>
              </div>
              <div className="row container-grid nf-col-3">
                {project.images.map((image, index) => (
                  <div key={image.id} className="nf-item spacing">
                    <div
                      className="item-box"
                      onClick={() => {
                        setPhotoIndex(index)
                        setIsOpen(true)
                      }}
                    >
                      <Img
                        fluid={image.fluid}
                        alt={image.title}
                        title={image.title}
                      />
                      <div className="link-zoom">
                        <i className="fa fa-search-plus" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SingleGallery
